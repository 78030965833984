.form-group {
  margin-bottom: 0rem;
}

.p-button {
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
  font-size: 14px;
}


.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.571rem 0.857rem;
  border: 1px solid #c8c8c8;
  border-width: 1px 1px 1px 1px;
  font-weight: 700;
  color: #333333;
  background: #f4f4f4;
  transition: background-color 0.2s, box-shadow 0.2s;
  font-size: 11px;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #c8c8c8;
  border-width: 1px;
  padding: 0.571rem 0.857rem;
  font-size: 12px;
}

label {
  display: inline-block;
  margin-bottom: -0.5rem;
  font-size: 13px;
}

textarea {
  overflow: auto;
  resize: vertical;
  width: 100%;
}

.product-item {
  /* .product-item-content {
      border: 1px solid var(--surface-d);
      border-radius: 3px;
      margin: .3rem;
      text-align: center;
      padding: 2rem 0;
  } */

  /* .product-image {
      width: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
  } */
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  background-size: 240px;
}

.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  animation-delay: 1s;
}

.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  animation-delay: 1s;
}

.item-1 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #007bff;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
      transform: scale(1);
  }

  50%,
  75% {
      transform: scale(2.5);
  }

  78%,
  100% {
      opacity: 0;
  }
}

.item-1:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 200ms;
  transition: 0.2s all ease;
  transform: scale(1);
}

.item-2 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #00084a;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
      transform: scale(1);
  }

  50%,
  75% {
      transform: scale(2.5);
  }

  78%,
  100% {
      opacity: 0;
  }
}

.item-2:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 400ms;
  transition: 0.2s all ease;
  transform: scale(1);
}

.item-3 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #007bff;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
      transform: scale(1);
  }

  50%,
  75% {
      transform: scale(2.5);
  }

  78%,
  100% {
      opacity: 0;
  }
}


.item-3:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 600ms;
  transition: 0.2s all ease;
  transform: scale(1);
}


.p-scrollpanel .p-scrollpanel-bar {
  background: #1976d2;
}

p-autoComplete>span>input {
  width: 100% !important;
  /* border: 0px solid #d6d6d6 !important; */
}
p-autoComplete.form-control {
  height: 30px;
  padding: 0px 14px 0px 1px !important;
  border: 0px solid;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
  width: 100% !important;
}

.p-autocomplete {
  width: 100%;
}

p-dropdown>div {
  width: 100% !important;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  color: #555555;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  font-size: 13px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 13px;
}

.p-datepicker table th {
  text-align: center;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 4px solid #c8c8c8;
  border-width: 1px;
  padding: 4px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #f3f4ff;
  color: #000000;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #000000;
}

.p-tree-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  gap: 10px;
}

@media (min-width: 1600px) {
  .p-tree-container {
    grid-template-columns: repeat(3, 1fr); /* Cambiar a 3 columnas fijas cuando la pantalla sea al menos 800px de ancho */
  }
}
